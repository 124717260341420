import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { StaticRoutes } from '../../configuration/constants';
import { FieldInterface } from '../../components/input/types';
import { Mode, PageInterface } from '../../types';
import { getUserInitialState } from '../../utils/getters';

export type LoaderType = false | 'linear' | 'circular' | 'toast-err' | 'toast-ok';

export type StatusCode = 200 | 400 | 401 | 403 | 404 | 500;

export interface LoadingPayload {
    type: LoaderType;
    status?: StatusCode;
    description?: string;
}

export interface Model {
    [key: string]: string | number | boolean | null;
}

export interface PrimaryColor {
    '--bt-nav-active': `#${string}`;
    '--bt-primary-100': `#${string}`;
    '--bt-primary': `#${string}`;
    '--bt-primary-75': `#${string}`;
    '--bt-primary-50': `#${string}`;
    '--bt-primary-25': `#${string}`;
    '--bt-primary-10': `#${string}`;
    '--bt-primary-contrast': `#${string}`;
}

export type PrimaryColors = 'blue' | 'red' | 'purple' | 'green' | 'grey' | 'yellow';
type ColorSchemes = Record<PrimaryColors, PrimaryColor>;

export type AppTheme = 'dark-theme' | 'green-theme' | 'light-theme';

const primaryColors: ColorSchemes = {
    blue: {
        '--bt-nav-active': '#66b2ff',
        '--bt-primary-100': '#0056b3',
        '--bt-primary': '#66b2ff',
        '--bt-primary-75': '#3399ff',
        '--bt-primary-50': '#66b2ff',
        '--bt-primary-25': '#99ccff',
        '--bt-primary-10': '#99ccff15',
        '--bt-primary-contrast': '#fff',
    },
    yellow: {
        '--bt-nav-active': '#ffd500',
        '--bt-primary-100': '#cc9900',
        '--bt-primary': '#ffd500',
        '--bt-primary-75': '#ffda50',
        '--bt-primary-50': '#ffeea0',
        '--bt-primary-25': '#fff3cc',
        '--bt-primary-10': '#fff3cc1a',
        '--bt-primary-contrast': '#000',
    },
    red: {
        '--bt-nav-active': '#ff6666',
        '--bt-primary-100': '#b30000',
        '--bt-primary': '#ff6666',
        '--bt-primary-75': '#ff3333',
        '--bt-primary-50': '#ff6666',
        '--bt-primary-25': '#ff9999',
        '--bt-primary-10': '#ff999915',
        '--bt-primary-contrast': '#fff',
    },
    purple: {
        '--bt-nav-active': '#b266ff',
        '--bt-primary-100': '#4d0099',
        '--bt-primary': '#b266ff',
        '--bt-primary-75': '#9933ff',
        '--bt-primary-50': '#b266ff',
        '--bt-primary-25': '#cc99ff',
        '--bt-primary-10': '#cc99ff15',
        '--bt-primary-contrast': '#fff',
    },
    green: {
        '--bt-nav-active': '#66ff66',
        '--bt-primary-100': '#008000',
        '--bt-primary': '#66ff66',
        '--bt-primary-75': '#33ff33',
        '--bt-primary-50': '#66ff66',
        '--bt-primary-25': '#99ff99',
        '--bt-primary-10': '#99ff9915',
        '--bt-primary-contrast': '#fff',
    },
    grey: {
        '--bt-nav-active': '#b3b3b3',
        '--bt-primary-100': '#404040',
        '--bt-primary': '#b3b3b3',
        '--bt-primary-75': '#808080',
        '--bt-primary-50': '#b3b3b3',
        '--bt-primary-25': '#d1d1d1',
        '--bt-primary-10': '#d1d1d115',
        '--bt-primary-contrast': '#fff',
    },
};

export type RouteName =
    '_themes'
    | '_user_settings'
    | '_languages'
    | '_roles'
    | '_metadata_pages'
    | '_widgets'
    | '_user_profile';

export type AppRoutes = Partial<Record<RouteName, string>>;

export interface AppState {
    theme: AppTheme;
    pages: PageInterface[] | null;
    loader: {
        type: LoaderType | false;
        status: StatusCode;
        description: string;
    };
    asideShow: boolean;
    sidebarShow: boolean;
    sidebarUnfoldable: boolean;
    MainSubheader: JSX.Element | null;
    sidebarHover: boolean;
    primaryColor: PrimaryColor;
    rtl: boolean;
    dropdownButtons: Partial<RouteName>[];
    headerButtons: Partial<RouteName>[];
    routes: AppRoutes | null; // Null means we don't have the routes yet
    runtime: {
        [key: string]: Record<string, unknown | string | Mode>;
    };
    views: Partial<Record<keyof StaticRoutes, object>>;
}

const initialState: AppState = {
    ...getUserInitialState(),
    runtime: {},
};

export const appParams = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppRoutes: {
            reducer: (state, action: PayloadAction<AppRoutes>) => {
                const routes = action.payload;
                if (routes) {
                    state.routes = routes;
                }
            },
            prepare: (routes: AppRoutes) => ({ payload: routes }),
        },
        dismountForm: {
            reducer: (state, action: PayloadAction<{ formId: string }>) => {
                const { formId } = action.payload;
                _.set(state.runtime, formId, undefined);

            },
            prepare: (formId: string) => ({ payload: { formId } }),
        },
        initializeForm: {
            reducer: (state, action: PayloadAction<{
                formId: string;
                fields: FieldInterface[],
                defaultValues?: Record<string, string>
            }>) => {
                const { formId, fields } = action.payload;
                _.set(state.runtime, `${formId}.fields`, fields);
            },
            prepare: (formId: string, fields: FieldInterface[], defaultValues?: Record<string, string>) => ({
                payload: {
                    formId,
                    fields,
                    defaultValues,
                },
            }),
        },
        updateView: (state, action: PayloadAction<{ viewName: keyof StaticRoutes; content: object }>) => {
            const { viewName, content } = action.payload;
            // Update the state for the specified view
            state.views[viewName] = content;
        },
        updateRuntimeData: {
            reducer: (state, action: PayloadAction<{
                key: string;
                value: object | string;
                strategy: 'merge' | 'override'
            }>) => {
                const { key, value } = action.payload;

                _.set(state.runtime, key, value);
            },
            prepare: (key: string, value: object | string, strategy: 'merge' | 'override' = 'merge') => ({
                payload: {
                    key,
                    value,
                    strategy,
                },
            }),
        },
        setRtl: (state, action: PayloadAction<boolean>) => {
            state.rtl = action.payload;
            state.asideShow = false;
        },
        setPriamryColor: (_, action: PayloadAction<PrimaryColors>) => {
            const currTheme = primaryColors[action.payload];

            for (const [key, value] of Object.entries(currTheme)) {
                document.documentElement.style.setProperty(key, value);
            }
        },
        setAsideShow: (state, action: PayloadAction<boolean>) => {
            state.asideShow = action.payload;
        },
        setSidebarShow: (state, action: PayloadAction<boolean>) => {
            state.sidebarShow = action.payload;
        },
        setSidebarHover: (state, action: PayloadAction<boolean>) => {
            state.sidebarHover = action.payload;
        },
        setSidebarUnfoldable: (state, action: PayloadAction<boolean>) => {
            let unfoldable: boolean;

            // Mobile devices don't have foldable sidebars
            if (window.innerWidth < 768) {
                unfoldable = false;
            } else {
                unfoldable = action.payload;
            }

            state.sidebarUnfoldable = unfoldable;
          if (unfoldable) {
            document.body.classList.remove('sidebar-is-expanded');
          } else {
            document.body.classList.add('sidebar-is-expanded');
          }
        },
        changeTheme: (state, action: PayloadAction<Partial<AppTheme> | 'toggle'>) => {
            // remove current theme;
            const currTheme = state.theme;
            document.documentElement.className = document.documentElement.className.replaceAll(currTheme, '');

            let theme;

            // 0 - dark theme, 1 - light theme, 2 - green theme
            switch (action.payload) {
                case '0':
                    theme = 'dark-theme';
                    break;
                case '1':
                    theme = 'light-theme';
                    break;
                case '2':
                    theme = 'green-theme';
                    break;
            }

            state.theme = theme;
            document.body.className = theme;
            document.documentElement.className = document.documentElement.className + ' ' + theme;
        },

        setPages: (state, action: PayloadAction<PageInterface[]>) => {
            state.pages = action.payload;
        },
        setLoading: (state, action: PayloadAction<LoadingPayload>) => {
            state.loader.type = action.payload.type;
            state.loader.description = action.payload.description || 'Error communicating with the server.';
            state.loader.status = action.payload.status || 400;
        },
        setMainSubheader: (state, action: PayloadAction<JSX.Element | null>) => {
            // If there is already a custom subheader, throw a warning
            if (state.MainSubheader && action.payload !== null) {
                console.warn('There is already a custom subheader and it should not get overridden.');
                state.MainSubheader = action.payload;
            } else {
                state.MainSubheader = action.payload;
            }
        },
    },
});

export const {
    setRtl,
    setPages,
    setLoading,
    setMainSubheader,
    changeTheme,
    setAsideShow,
    setSidebarShow,
    setSidebarUnfoldable,
    setSidebarHover,
    setPriamryColor,
    updateRuntimeData,
    updateView,
    initializeForm,
    setAppRoutes,
    dismountForm,
} = appParams.actions;

export const appStateStore = configureStore({
    reducer: {
        app: appParams.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export type AppDispatch = typeof appStateStore.dispatch;
export type RootState = ReturnType<typeof appStateStore.getState>;
