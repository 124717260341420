import { CLoadingButton } from '@coreui/react-pro';
import _ from 'lodash';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import useAppSelect from '../../services/redux/useAppSelect';
import useSendMessage from '../../services/websocket/webSocket';
import {
  createPermissionIndex,
  deletePermissionIndex,
  FieldInterface,
  readPermissionIndex,
  writePermissionIndex,
} from '../input/types';
import { Mode, Operation, WidgetInterface } from '../../types';
import { formatDate, getSource } from '../../utils';
import Icon from '../mui-icon/Icon';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAppDispatch from '../../services/redux/useAppDispatch';
import { updateRuntimeData } from '../../services/redux/globalStateManager';

interface SubmitProps {
    widget: WidgetInterface;
}

const deepEqual = (a: unknown, b: unknown) => {
    return a === b || JSON.stringify(a) === JSON.stringify(b);
};

export const getValueAsType = (value, fieldMetaData: FieldInterface) => {
    const { type } = fieldMetaData;
    const { subtype } = fieldMetaData;

    switch (type) {
        case 'number':
        case 'telephone':
            return parseInt(value);
        case 'boolean':
            return Boolean(value);
        case 'text':
        case 'letters':
        case 'chars':
        case 'textarea':
        case 'email':
        case 'password':
        case 'radio':
        case 'location':
        case 'link':
            return value;
        case 'date':
        case 'time':
        case 'dateAndTime':
            return formatDate(value);
        case 'selection':
            if (subtype === 'multiple' && !Array.isArray(value)) {
                return value.split(',').map(item => item.trim());
            } else {
                return value;
            }
        case 'file':
            return value;
        case 'image':
        case 'video':
        case 'document':
        case 'audio':
            return value;
        default:
            return value;
    }
};

const Submit = (props: PropsWithChildren<SubmitProps>) => {
    const runtime = useAppSelect('runtime');
    const dispatch = useAppDispatch();
    const { sendMessage } = useSendMessage();
    const { widget } = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [source, setSource] = useState({});
    const lastCallTime = useRef(0);
    const [validationReason, setValidationReason] = useState(null);

    const handleClick = useCallback(() => {
        const now = Date.now();
        if (now - lastCallTime.current < 500) {
            // 500ms = twice per second
            return;
        }
        lastCallTime.current = now;

        const data = {};

        // Make sure form values have correct types i.e. 'id' is a number
        widget?._content?.forEach(filed => {
          const val = _.get(runtime, `${widget._id}.${filed._key}`)?.value;
          if (val) {
            data[filed._key] = getValueAsType(val, filed);
          }
        });

        setLoading(true);
        const mode = runtime[widget._id]?.mode === Mode.EDIT;
        sendMessage(props.widget._path._id ?? '', mode ? 'UPDATE' : 'CREATE', data)
            ?.then(() => {
                setLoading(false);
                dispatch(updateRuntimeData(props.widget?._id + '.mode', Mode.VIEW, 'override'));
            })
            .catch(error => {
                setLoading(false);
                setValidationReason(error ?? t('Operation failed!'));
                console.error(error);
            });
    }, [runtime, widget._content, sendMessage, dispatch]);

    useEffect(() => {
        getSource(widget.icon).then(value => setSource(value));

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleClick();
            }
        };

        const targetElem = document.getElementById(widget._id);
        if (targetElem) {
            targetElem.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (targetElem) {
                targetElem.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [widget.icon, widget._id, handleClick]);

    useEffect(() => {
        let hasErrors = false;
        let hasChanged = false;

        const id = widget._id;
        const formStateSource = _.get(runtime, `${widget._id}.fields`);
        formStateSource?.forEach(field => {
          let goAhead = true;
          if (field.permission) {
            switch (runtime[id]?.mode) {
              case Mode.CREATE:
                goAhead = String(field.permission)[createPermissionIndex] === '1';
                break;
              case Mode.EDIT:
                goAhead = String(field.permission)[writePermissionIndex] === '1';
                break;
            }
          }

          if (!goAhead) {
            return;
          }
          const fieldData = _.get(runtime, `${id}.${field._key}`);
          if ((fieldData?.validated && fieldData?.errorMessage) || fieldData?.errors) {
            hasErrors = true;
            setValidationReason(fieldData?.errorMessage || 'Field has errors'); // Set reason
          }

          if (field?.required && (!fieldData?.validated || !fieldData.value)) {
            hasErrors = true;
            setValidationReason('Required field is missing or not validated'); // Set reason
          }

          if (fieldData?.value && fieldData?.value !== '') {
            hasChanged = true;
          }

          if (field?.defaultValue && !deepEqual(field?.defaultValue, fieldData.value ?? '')) {
            hasChanged = true;
          }
        });

        setIsDisabled(hasErrors || !hasChanged);

        // Clear validation reason if no errors
        if (!hasErrors && hasChanged) {
            setValidationReason(null);
        }
    }, [runtime, _.get(`${widget._id}`)]);

    return (
        <Stack spacing={2} direction="column">
            {error ? <span style={{ color: 'red' }}>{t('Error: ') + error.description + ` (${error.code})`}</span> : null}

            <CLoadingButton style={{ width: 'fit-content' }} color="primary" disabled={isDisabled} loading={loading} onClick={handleClick}>
                {widget.label && <span>{t(widget.label)}</span>}
                {widget.icon && source && <Icon name={source} />}
            </CLoadingButton>

            {process.env.NODE_ENV === 'development' && (
                <>
                    <p>{validationReason}</p>
                </>
            )}
        </Stack>
    );
};

export default Submit;
