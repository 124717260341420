import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Paper,
  IconButton,
  TextField,
  Box,
  Button,
  Collapse,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { keyframes } from '@mui/system';
import Label from '../Label';
import { FieldInterface } from '../../input/types';

interface Item {
  name: string;
  count: string;
  id: string;
}

interface CallbackData {
  total: number;
  items: Array<{
    name: string;
    count: string;
  }>;
}

interface TestComponentProps {
  onChange?: (data: CallbackData) => void;
  field: FieldInterface
}


const ExtendableItems: React.FC<TestComponentProps> = ({ onChange, field }) => {

  const { t } = useTranslation();
  const [items, setItems] = useState<Item[]>([{
    name: '',
    count: '',
    id: Date.now().toString()
  }]);
  const [isFocused, setIsFocused] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const previousCount = useRef<number>(0);

  const getTotalCount = (): number => {
    return items.reduce((sum, item) => {
      const count = parseInt(item.count) || 0;
      return sum + count;
    }, 0);
  };

  // Helper function to prepare callback data
  const prepareCallbackData = (currentItems: Item[]): CallbackData => {
    const data = {
      total: currentItems.reduce((sum, item) => sum + (parseInt(item.count) || 0), 0),
      items: currentItems.map(item => ({
        name: item.name,
        count: item.count,
      }))
    };
    console.log('Input value changed:', data);
    return data;
  };

  // Update all handlers to trigger the callback
  const handleAddItem = () => {
    const newItems = [
      { name: '', count: '', id: Date.now().toString() },
      ...items
    ];
    setItems(newItems);
    onChange && onChange(prepareCallbackData(newItems));
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    onChange && onChange(prepareCallbackData(newItems));
  };

  const handleChangeItem = (index: number, field: keyof Omit<Item, 'id'>, value: string) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [field]: value
    };
    setItems(newItems);
    onChange && onChange(prepareCallbackData(newItems));
  };

  useEffect(() => {
    const currentCount = getTotalCount();
    if (currentCount !== previousCount.current) {
      setShouldAnimate(true);
      const timer = setTimeout(() => setShouldAnimate(false), 500);
      previousCount.current = currentCount;
      return () => clearTimeout(timer);
    }
  }, [items]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Initialize callback with initial state
  useEffect(() => {
    onChange && onChange(prepareCallbackData(items));
  }, []);

  return (
    <Box
      ref={componentRef}
      sx={{
        maxWidth: 800,
        mx: 'auto',
        p: 1,
      }}
    >


      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>

        {
          <Label required={field.required}>
            {field.label}
          </Label>
        }
        {
          isFocused && (
            <Button
              onClick={() => setIsFocused(false)}
              variant="outlined"
              startIcon={<CloseIcon sx={{ '& *': { margin: 0 } }} style={{ margin: '0 !important' }} />}
              sx={{
                width: 28,
                height: 28,
                minWidth: 'auto',
                border: 'none',
                aspectRatio: 1,
                borderRadius: '100%',
                backgroundColor: 'rgba(0,0,0,0.1)',
                '& *': { margin: 0 }
              }}
            >
            </Button>
          )
        }

      </Box>

      <TextField
        value={t('Total') + ': ' + getTotalCount()}
        onClick={() => setIsFocused(true)}
        fullWidth
        variant="outlined"
        size="small"
        InputProps={{
          readOnly: true,
        }}
        sx={{
          mt: 1,
          '& .MuiInputBase-input': {
            color: 'var(--bt-primary-test)',
            cursor: 'pointer',
            backgroundColor: 'var(--cui-input-bg)',
          },
          '& .MuiInputBase-root': {
            animation: shouldAnimate ? `${flashAnimation} 750ms ease-out` : 'none',
            backgroundColor: 'var(--cui-input-bg)',
            borderColor: 'var(--bt-bg-2)',
            '&:hover': {
              borderColor: 'var(--bt-primary-test-10)',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        }}
      />

      <Collapse in={isFocused}>
        <Box>
          <Box sx={{
            display: 'flex',
            gap: 2,
            mt: 2,
            mb: 2,
            justifyContent: 'space-between'
          }}>
            <Button
              onClick={handleAddItem}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                borderStyle: 'dashed',
              }}
            >
              {t('Add Item')}
            </Button>
          </Box>

          <Box
            sx={{
              maxHeight: 500,
              overflowY: 'auto',
              transition: 'max-height 200ms ease-in-out',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.2)',
                borderRadius: '3px',
              },
            }}
          >
            <TransitionGroup>
              {items.map((item, index) => (
                <Collapse key={item.id}>
                  <Paper
                    elevation={0}
                    sx={{
                      mb: 2,
                      backgroundColor: 'var(--bt-bg)',
                      borderRadius: 1,
                      '&:hover': {
                        '& .remove-button': {
                          opacity: 1,
                        },
                      },
                    }}
                  >
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      overflow: 'hidden',
                      minWidth: 0
                    }}>
                      <TextField
                        value={item.name}
                        onChange={(e) => handleChangeItem(index, 'name', e.target.value)}
                        placeholder={t('Item Name')}
                        variant="outlined"
                        size="small"
                        sx={{
                          flex: 2,
                          '& .MuiInputBase-input': {
                            color: 'var(--bt-primary-test)',
                            backgroundColor: 'var(--cui-input-bg)',
                          },
                          '& .MuiInputBase-root': {
                            backgroundColor: 'var(--cui-input-bg)',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--bt-bg-2)',
                          },
                          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--bt-primary-test-10)',
                          },
                        }}
                      />
                      <TextField
                        value={item.count}
                        onChange={(e) => handleChangeItem(index, 'count', e.target.value)}
                        placeholder={t('Item Count')}
                        variant="outlined"
                        size="small"
                        sx={{
                          flex: 1,
                          '& .MuiInputBase-input': {
                            color: 'var(--bt-primary-test)',
                            backgroundColor: 'var(--cui-input-bg)',
                          },
                          '& .MuiInputBase-root': {
                            backgroundColor: 'var(--cui-input-bg)',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--bt-bg-2)',
                          },
                          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--bt-primary-test-10)',
                          },
                        }}
                        type="number"
                      />
                      <IconButton
                        className="remove-button"
                        onClick={() => handleRemoveItem(index)}
                        size="small"
                        sx={{
                          opacity: 0,
                          transition: 'opacity 200ms ease',
                        }}
                      >
                        <CloseIcon fontSize="small" sx={{ color: 'var(--bt-primary-test)' }} />
                      </IconButton>
                    </Box>
                  </Paper>
                </Collapse>
              ))}
            </TransitionGroup>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ExtendableItems;

const flashAnimation = keyframes`
  0% {
    background-color: transparent;
  }
  25% {
    background-color: var(--bt-bg-10);
  }

`;
