import WebSocketSingleton, { MessageBody } from './WebSocketSingleton';
import { Operation } from '../../types';

function webSocket() {
    const sendMessage = (url: string, operation: Operation, body?: MessageBody) => {
      return WebSocketSingleton.send(url, operation, body);
    };
    return { sendMessage };
}

export default webSocket;
