import { CCol, CRow } from '@coreui/react';
import { CButton, CContainer, CFormLabel, CHeader } from '@coreui/react-pro';
import { Divider } from '@mui/material';
import React, { RefCallback } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { PrimaryColors, changeTheme, setPriamryColor, setRtl } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import webSocket from '../../services/websocket/webSocket';
import { Settings, User } from '../../types';
import { isRtlLang } from '../../validators';
import Info from '../Info';
import StyleWrapper from '../style-wrapper';
import RevertButton from './RevertButton';

const primaryColors: PrimaryColors[] = ['red', 'blue', 'green', 'grey', 'purple', 'yellow'];
const fontSizes = ['12px', '14px', '16px', '18px', '20px', '22px'];
const appFonts = ['Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Verdana'];
const languages = ['he', 'en', 'de', 'fr'];
const langLabels = ['hebrew', 'english', 'german', 'french'];

const sidebarThemes: Record<string, string>[] = [
    {
        name: 'dark-theme',
        color: '#333446',
        sidebar: 'dark',
    },
    {
        name: 'light-theme',
        color: '#ffffff',
        sidebar: 'light',
    },
    {
        name: 'green-theme',
        color: '#003d3d',
        sidebar: 'green',
    },
];

const isSelectedStyles = (setting: string, savedSetting: string) => {
    if (setting === savedSetting) {
        return {
            boxShadow: '0 0 0 2px white, 0 0 0 4px gold',
            borderRadius: '2px',
            transform: 'scale(1.05)',
        };
    } else {
        return {};
    }
};

export const getDefaultValues = (user: User) => ({
    primaryColor: user?.primaryColor ?? 'blue',
    appFont: user?.appFont ?? 'Arial',
    fontSize: user?.fontSize ?? '18px',
    tFormat: user?.tFormat,
    dFormat: user?.dFormat,
    language: user?.language,
    defaultTheme: user?.defaultTheme ?? 'dark-theme',
    image: user?.image,
    sidebarTheme: user?.sidebarTheme ?? 'dark-theme',
});

export const setAppProperties = (data: User | Settings, dispatch, i18n) => {
    const theme = data?.defaultTheme ?? data?._themes;
    const primaryColor = data?.primaryColor;
    const appFont = data?.appFont;
    const fontSize = data?.fontSize;
  const language = data?.language ?? data?._languages ?? Intl.DateTimeFormat().resolvedOptions().locale ?? 'en-US';
    const sidebarTheme = data?.sidebarTheme;
    if (theme) dispatch(changeTheme(theme));
    if (sidebarTheme) {
        setSidebarTheme(sidebarTheme);
    }



    if (language) {
        const changeLanguage = typeof language === 'object' ? language._id : language;
        i18n.changeLanguage(changeLanguage);
        if (isRtlLang(language.toString())) {
            // Remove ltr class and add rtl class
            document.body.className = document.body.className.replaceAll('ltr', '');
            document.body.className = document.body.className + ' rtl';
            document.documentElement.dir = 'rtl';
            dispatch(setRtl(true));
        } else {
            // Remove rtl class and add ltr class
            document.body.className = document.body.className.replaceAll('rtl', '');
            document.body.className = document.body.className + ' ltr';
            document.documentElement.dir = 'ltr';
            dispatch(setRtl(false));
        }
    }
    if (primaryColor) dispatch(setPriamryColor(primaryColor));

    if (fontSize) document.documentElement.style.fontSize = fontSize;
    if (appFont) document.documentElement.style.fontFamily = appFont;
};

export const setSidebarTheme = (themeName: string) => {
    sidebarThemes.forEach(theme => {
        const removeTheme = `sidebar-${theme.sidebar}`;
        document.documentElement.className = document.documentElement.className.replace(removeTheme, '');
    });

    document.documentElement.className = document.documentElement.className + ` ${themeName}`;
};

type FormValues = {
    primaryColor: string;
    defaultTheme: string;
    sidebarTheme: string;
    appFont: string;
    fontSize: string;
    language: string;
};

type SettingRow = {
    id: keyof FormValues;
    label: string;
    info?: string;
    content: JSX.Element | JSX.Element[];
};

type RenderComponentProps = {
    onClick?: () => void;
    ref?: RefCallback<unknown>;
    color?: string;
    className?: string;
    style?: React.CSSProperties;
    autoComplete?: 'off' | 'on';
    label?: string;
    type?: string;
    button?: {
        color: string;
        variant: string;
    };
};

interface Options {
    source: string[] | { name: string; icon: string }[];
    RenderComponent: React.FC<RenderComponentProps>;
    itemStyle?: (item: unknown) => React.CSSProperties;
    control: Control<FieldValues>;
    itemOnClick?: (item: unknown) => void;
    id: keyof FormValues;
}

const createOptions = (options: Options) => {
    const { RenderComponent, itemStyle, source, itemOnClick, control, id } = options;

    const content = source.map(item => {
        const name = item.name ?? item;

        return (
            <Controller
                key={name}
                name={id}
                control={control}
                render={({ field: { onChange, ref } }) => (
                    <RenderComponent
                        onClick={() => {
                            onChange(name);
                            itemOnClick?.(item);
                        }}
                        ref={ref}
                        key={name}
                        color="secondary"
                        className="m-1 py-4 px-5"
                        style={
                            itemStyle
                                ? itemStyle(item)
                                : {
                                      color: 'red !important',
                                      backgroundColor: 'black !important',
                                  }
                        }
                        autoComplete="off"
                        label={name}
                        type="radio"
                        button={{ color: 'primary', variant: 'outline' }}
                    />
                )}
            />
        );
    });

    return {
        content,
        id,
    };
};

const SettingsPanel = () => {
    const { t, i18n } = useTranslation();
    const userRef = React.useRef(JSON.parse(localStorage.getItem('user') ?? ''));
    const dispatch = useAppDispatch();
    const defaultTheme = useAppSelect('theme');
    const { sendMessage } = webSocket();

    const defaultValues = {
        ...getDefaultValues(userRef.current),
        defaultTheme,
        language: i18n.language,
    };

    const { register, control, watch, handleSubmit, reset } = useForm<FormValues>({
        defaultValues,
    });

    const watchChanges = watch();

    React.useEffect(() => {
        const changes = {
            ...watchChanges,
            defaultTheme,
            language: i18n.language,
            sidebarTheme: '',
        };

        setAppProperties(changes, dispatch, i18n);
    }, [JSON.stringify(watchChanges)]);

    const onSubmit = data => {
        delete userRef.current['pages'];
        const updatedUser = {
            ...userRef.current,
            ...data,
            defaultTheme,
            language: i18n.language,
        };

        sendMessage('/user', 'UPDATE', updatedUser, 'update-user-' + new Date().getTime())
          ?.then((result) => {
            console.info('Received response: ' + JSON.stringify(result));
          })
          .catch(error => {
            console.error(error);
          });

        reset(data);
        toast.success(t('Your settings have been saved.'));
    };

    const handleCancel = () => {
        localStorage.setItem('user', JSON.stringify(userRef.current));
        reset(getDefaultValues(userRef.current));
        setAppProperties(getDefaultValues(userRef.current), dispatch, i18n);

        toast(t('Reverted to last saved settings.'), {
            icon: 'ℹ',
        });
    };

    // Higher-order function for reset event handler
    const handleReset = name => () => resetSpecificSetting(name);

    const savedIsCurrent = () => {
        const currentSaved = getDefaultValues(JSON.parse(localStorage.getItem('user') ?? '{}'));

        return JSON.stringify(currentSaved) === JSON.stringify(watchChanges);
    };

    const resetSpecificSetting = (name: string) => {
        const setting = getDefaultValues(userRef.current);

        if (setting) {
            const settingToChange = setting[name];

            if (settingToChange) {
                const newSettings = {
                    ...watchChanges,
                    [name]: settingToChange,
                };

                setAppProperties(newSettings, dispatch, i18n);
                reset(newSettings);
            }
        }
    };

    return (
        <StyleWrapper padding="4rem" overflowY="auto" overflowX="hidden" width="100%" {...(isRtlLang(i18n.language) ? { marginLeft: '20%' } : { marginRight: '20%' })}>
            <CContainer>
                <CHeader>
                    <h1>{t('User Settings')}</h1>
                </CHeader>
                <Divider className="py-2" />

                {/* Iterate over rows array to render each row */}
                {rows.map(row => (
                    <CRow key={row.id}>
                        <CCol md="12">
                            <CFormLabel htmlFor={row.id}>{row.label}</CFormLabel>
                            {row.info && <Info popup={row.info} />}
                            <div className="d-flex flex-wrap">
                                {row.content}
                                <RevertButton reset={reset} name={row.id} onClick={handleReset(row.id)} />
                            </div>
                        </CCol>
                    </CRow>
                ))}

                <Divider className="py-2" />
                <CRow>
                    <CCol md="12" className="d-flex justify-content-end">
                        <CButton disabled={savedIsCurrent()} onClick={handleSubmit(onSubmit)} color="primary" className="me-2">
                            {t('Save')}
                        </CButton>
                        <CButton disabled={savedIsCurrent()} onClick={handleCancel} color="danger" variant="outline">
                            {t('Cancel')}
                        </CButton>
                    </CCol>
                </CRow>
            </CContainer>
        </StyleWrapper>
    );
};

export default SettingsPanel;
