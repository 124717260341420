import React, { CSSProperties, ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAppSelect from '../../services/redux/useAppSelect';
import Icon from '../mui-icon/Icon';
import Label from '../form/Label';
import { Box } from '@mui/material';
import { FieldInterface } from './types';

const BaseInput = (props: { field: FieldInterface }) => {
    const [value, setValue] = useState(props?.field?.value ?? '');
    const [isFocused, setFocused] = useState(props.shouldFocus);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const isRtl = useAppSelect('rtl');
    const specialSigns = /^[\p{L}\s]*$/u; // Alphabetic characters and spaces across multiple languages

    React.useEffect(() => {
        if (props.shouldFocus) {
            setFocused(true);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e?.target?.value);
        }
    };

    const onBlur = () => {
        setFocused(false);
    };

    const onFocus = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setFocused(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const labelStyle: CSSProperties = {
        position: 'absolute',
        left: isRtl ? '10px' : '',
        right: isRtl ? '0' : '',
        marginTop: isFocused || String(value) ? '2px' : '12px',
        fontSize: isFocused || String(value) ? '12px' : '16px',
        transition: '0.2s ease',
        cursor: 'text',
    };

    const getInputType = () => {
        if (props.field.type === 'password') {
            return showPassword ? 'text' : 'password';
        }

        return 'text';
    };

    return (
        <div onFocus={onFocus} onMouseDown={onFocus} onBlur={onBlur} style={{ position: 'relative', ...props.style}}>
            {props.field.label && (
                <Label required={props.field.required} htmlFor={'form-input' + t(props.field.label) ?? props.field.label} style={labelStyle}>
                    {t(props.floatingLabel ?? props.field.label)}
                </Label>
            )}

            <input
                autoFocus={props.ind === 0}
                // Important: We override the type to be text to manually handle the number input logic
                type={getInputType()}
                id={'form-input' + props.field.label}
                ref={inputRef}
                value={value}
                onChange={e => {
                    if (props.field.type === 'letters' && !specialSigns.test(e.target.value) && e.target.value !== '') {
                      return;
                    }

                    if (props.field.type === 'number' && isNaN(Number(e.target.value))) {
                          // If the user typed a letter, overide the onChange to keep the value the same
                          return;
                    }
                    setValue(e.target.value);
                    onChange(e);
                }}
                style={{
                    backgroundColor: 'transparent',
                    outline: 'none',
                    color: 'var(--cui-input-color)',
                    paddingLeft: '10px',
                    paddingBottom: '5px',
                    paddingTop: '20px',
                    border: 'none',
                    borderBottom: 'none',
                    width: '100%',
                }}
            />

            {/* Show text button for password input */}
            {props.field.type === 'password' &&
                (!showPassword ? (
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            right: isRtl ? 'unset' : '0px',
                            left: isRtl ? '0px' : 'unset',
                            cursor: 'pointer',
                        }}
                    >
                        <Icon onClick={() => setShowPassword(!showPassword)} name="RemoveRedEye" />
                    </Box>
                ) : (
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            right: isRtl ? 'unset' : '0px',
                            left: isRtl ? '0px' : 'unset',
                            cursor: 'pointer',
                        }}
                    >
                        <Icon onClick={() => setShowPassword(!showPassword)} name="VisibilityOff" />
                    </Box>
                ))}
        </div>
    );
};

export default BaseInput;
