import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { useTranslation } from 'react-i18next';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { downloadTable } from '../../utils';
import Stack from '../layout-util/stack';
import { createPermissionIndex } from '../input/types';
import { Mode } from '../../types';

const BaseTableHeader = ({ title, widgetId, permission }) => {
    const { t } = useTranslation();
    const runtime = useAppSelect('runtime');
    const dispatch = useAppDispatch();
    const rows = runtime[widgetId]?.rows ?? [];

    const handleAddButton = () => {
        const mode = runtime[widgetId]?.mode;
        if (mode === Mode.VIEW) {
            dispatch(updateRuntimeData(`${widgetId}.mode`, Mode.CREATE)); // Will tell the table to display the create form
            dispatch(updateRuntimeData(`${widgetId}.activeRow`, {}));
            dispatch(updateRuntimeData(`${widgetId}.modal`, { title: `${t('addNew')} ${title}` }));
        } else {
            dispatch(updateRuntimeData(`${widgetId}.mode`, Mode.VIEW)); // Will tell the table to display the view mode
        }
    };

    return (
        <Stack direction="row" style={{ justifyContent: 'space-between' }}>
            <Stack direction="row">
                <h1>{t(title?.toLowerCase() ?? '')}</h1>
                <CDropdown variant="dropdown">
                    <CDropdownToggle color="primary">{t('Download') + ' ' + t('Data') + ' '}</CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem onClick={() => downloadTable(rows, 'excel')}>{t('Excel')}</CDropdownItem>
                        <CDropdownItem onClick={() => downloadTable(rows, 'pdf')}>{t('PDF')}</CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </Stack>
            {String(permission)[createPermissionIndex] === '1' && (
                <CButton type="button" color="primary" onClick={handleAddButton}>
                    {`${t('Add')} ${t(title?.toLowerCase() ?? '')}`}
                </CButton>
            )}
        </Stack>
    );
};

export default BaseTableHeader;
