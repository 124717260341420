import { Path } from '../../types';

interface ExtendPath extends Path {
    startsWith?: Path;
    endsWith?: Path;
    contains?: Path[];
}

export const getRoute = (path: Path | undefined) => {
    if (path === undefined) {
        return undefined;
    }
    let route = '';
    switch (path._type) {
        case 'Pattern':
            const extendPath = path as ExtendPath;
            if (extendPath.startsWith) {
                route = extendPath.startsWith._id;
            }
            if (extendPath.contains) {
                extendPath.contains.forEach(path => {
                    route = route + path._id;
                });
            }
            if (extendPath.endsWith) {
                route = route + extendPath.endsWith._id;
            }
            break;
        default:
            route = path._id;
            break;
    }
    if (route?.startsWith('/')) {
        return route;
    } else {
        return `/${route}`;
    }
};
