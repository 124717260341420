import { CircularProgress } from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import '../src/assets/scss/style.scss';
import AppLoader from './components/app-loader/AppLoader';
import Page from './components/page/Page';
import { getDefaultValues, setAppProperties } from './components/settings-panel/SettingsPanel';
import { staticRoutes } from './configuration/constants';
import { AppRoutes, setAppRoutes } from './services/redux/globalStateManager';
import useAppDispatch from './services/redux/useAppDispatch';
import useSendMessage from './services/websocket/webSocket';
import { PageInterface as PageType } from './types';
import DefaultLayout from './views/layout/DefaultLayout';
import UserProfile from './views/pages/profile';
import Public from './views/pages/public/Public';
import SettingsPage from './views/pages/settings';
import SystemInitialization from './views/pages/system-initialization/SystemInitialization';
import Roles from './views/pages/roles/Roles';

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

export const RouteLoader = () => {
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<PageType | null>(null);
    const { sendMessage } = useSendMessage();

    useEffect(() => {
      if (pathname) {
        sendMessage(pathname + '/widgets', 'GET').then(response => {
          const page = {
            _id: 'route-loader-' + pathname + '-',
            _url: pathname,
            _widgets: response ?? [],
            permission: [],
          };
          setPage(page);
        })
        ?.catch(error => {
          console.error(error);
        });
      }
    }, [pathname]);

    if (!page) {
        return <CircularProgress />;
    }

    return (
        <div className="app-loader-container">
            <AppLoader />
            <DefaultLayout>
                <Page layout="blankLayout" page={page} />
            </DefaultLayout>
        </div>
    );
};

const App = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { i18n } = useTranslation();
    const { sendMessage } = useSendMessage();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}');
        const defaultValues = getDefaultValues(user);
        setAppProperties(defaultValues, dispatch, i18n);
      sendMessage('/metadata', 'GET').then(response => {
        dispatch(setAppRoutes(response as AppRoutes));
      })
        ?.catch(error => {
          console.error(error);
        });
    }, [i18n]);

    useEffect(() => {
        // Update the current Url state whenever the url changes, except login page.
        if (
            !location.pathname.startsWith(staticRoutes.login) &&
            !location.pathname.startsWith(staticRoutes.page404) &&
            !location.pathname.startsWith(staticRoutes.page500) &&
            !location.pathname.startsWith(staticRoutes.systemInitialization)
        ) {
            localStorage.setItem('lastPage', location.pathname);
        }
    }, [location]);

    return (
        <Suspense>
            <AppLoader />
            <Toaster
                position="top-right"
                toastOptions={{
                    style: {
                        backgroundColor: 'var(--bt-bg-2)',
                        color: 'var(--bt-primary-test)',
                    },
                }}
            />
            <Routes>
                {/* Remove this route when the server is ready to serve /roles */}
                <Route
                    path="/public/roles"
                    element={
                        <DefaultLayout>
                            <Roles />
                        </DefaultLayout>
                    }
                />

                <Route path="/system/initialization" element={<SystemInitialization />} />
                <Route path="/public/:page/:subpage" element={<Public />} />
                <Route path="/public/:page/" element={<Public />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path={staticRoutes.page500} element={<Page500 />} />
                <Route path={staticRoutes.page404} element={<Page404 />} />
                <Route path={staticRoutes.login} element={<Login />} />
                <Route path={staticRoutes.login + '/:company'} element={<Login />} />
                <Route path={staticRoutes.dashboard} element={<RouteLoader />} />
                <Route path="/" element={<Navigate to={staticRoutes.dashboard} />} />
                <Route path="" element={<Navigate to={staticRoutes.dashboard} />} />
                <Route path="*" element={<RouteLoader />} />
            </Routes>
        </Suspense>
    );
};

export default App;
