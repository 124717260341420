import { useMediaQuery } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import useAppSelect from '../../services/redux/useAppSelect';

export const TextReveal: React.FC<PropsWithChildren> = (props) => {
    const sidebarHover = useAppSelect('sidebarHover');
    const sidebarUnfoldable = useAppSelect('sidebarUnfoldable');
    const isMobile = useMediaQuery('(max-width: 600px)');

    const shouldClose = () => {
        if (isMobile) {
            return false;
        }

        if (!sidebarUnfoldable) {
            return false;
        }

        if (sidebarHover) {
            return false;
        }

      return true;
    };

    return (
        <div>
            <div
                style={{
                    overflow: 'hidden',
                    padding: 0,
                }}
            >
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                        transition: 'max-width 0.15s ease-in',
                        padding: 0,
                        overflow: 'hidden',
              maxWidth: shouldClose() ? '0%' : '100%',
                    }}
                >
                    {props.children}
                </span>
            </div>
        </div>
    );
};
