import { CCol, CRow, CWidgetStatsB } from '@coreui/react-pro';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import webSocket from '../../services/websocket/webSocket';
import { WidgetProps } from '../page/Page';
import { getRoute } from '../../utils';

export interface SummaryData {
    bgColor: string;
    title: string;
    summarization: number;
    data?: number[];
}

const Summary = (props: WidgetProps) => {
    const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
    const [processed, setProcessed] = useState<ReactElement[]>([]);
    const { t } = useTranslation();
    const { sendMessage } = webSocket();
    
    useEffect(() => {
        if (props && props.widget && props.widget._content && Array.isArray(props.widget._content)) {
            props.widget._content.forEach(content => {
                if (content) {
                    const path = getRoute(content._path);
                    if (!path) {
                        return;
                    }
                    sendMessage(path, 'GET', content.query)
                    .then((response) => {
                        if (response) {
                            setSummaryData([{ id: content._id, value: response, type: content.query.type }]);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            });
        }
    }, []);
    
    useEffect(() => {
        if (summaryData === undefined || summaryData.length === 0) {
            return;
        }
        const data: ReactElement[] = [];
        summaryData.forEach(summary => {
            switch (summary.type) {
                case 'COUNT':
                    data.push(<CCol key={summary.id} xs={3}>
                        <CWidgetStatsB
                            className="mb-3"
                            color="primary"
                            inverse
                            title={t(summary.id)}
                            text={t(summary.type)}
                            value={summary.value}
                        />
                    </CCol>);
                    break;
            }
        });
        setProcessed(data);
    }, [summaryData]);
    
    return (
        <CRow className="mt-3">
            {processed.map((element: ReactElement) => element)}
        </CRow>
    );
};

export default Summary;
