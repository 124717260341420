import { CDatePicker } from '@coreui/react-pro';
import { FieldComponentProps } from '../Field';
import { useTranslation } from 'react-i18next';

const Date = (props: FieldComponentProps) => {
    const { t } = useTranslation();
    const onDateChangeDefault = (date: Date | null) => {
        console.warn('No change event supplied for Date Picker:', date);
    };

    return <CDatePicker onDateChange={props.onDateChange || onDateChangeDefault} placeholder={t(props.field.label ?? '')} />;
};

export default Date;
