import { CFooter } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { staticRoutes } from '../configuration/constants';
import useAppSelect from '../services/redux/useAppSelect';

const AppFooter = () => {
    const { t } = useTranslation();
    const isRtl = useAppSelect('rtl');

  return (
            <CFooter>
      <div className={'mx-auto '}>
                    <span className="mx-1">{t('Powered by')}</span>
                    <a href={staticRoutes.dashboard} rel="noopener noreferrer">
                        {t('BETec')}
                    </a>
                </div>
      </CFooter>
    );
};

export default React.memo(AppFooter);
