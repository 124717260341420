import { Buffer } from 'buffer';
import { Emitter } from '../../services/emitter/Emitter';
import { staticRoutes } from '../../configuration/constants';
import { ExtFile } from '../../types';
import WebSocketSingleton from '../../services/websocket/WebSocketSingleton';

class FileRetriever {
    private static pendingIds = new Map<string, Promise<ExtFile>>();
    private socket = WebSocketSingleton;
    
    constructor() {
    
    }
    
    public get(id: string): Promise<ExtFile> {
        let promise = FileRetriever.pendingIds.get(id);
        if (promise !== undefined) {
            return promise;
        }
        promise = new Promise((resolve, reject) => {
            if (!id) {
                reject(new Error('id is required'));
            }
            const chunks: Buffer[] = [];
            const onMessage = (message: {
                part: string | number;
                data: WithImplicitCoercion<string> | { [Symbol.toPrimitive](hint: 'string'): string; };
            }) => {
                chunks[message.part] = Buffer.from(message.data, 'base64');
            };
            Emitter.getInstance().on(`${staticRoutes.files}.${id}`, onMessage);
            this.socket.send(staticRoutes.files, 'GET', { filters: { _id: id } })
            .then(done => {
                try {
                    Emitter.getInstance().removeListener(`${staticRoutes.files}.${id}`, onMessage);
                    const file = new File([new Blob(chunks)], done.name, { type: done.type });
                    if (done.id) {
                        file['id'] = done.id;
                    }
                    FileRetriever.pendingIds.delete(id);
                    resolve(file);
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
        });
        FileRetriever.pendingIds.set(id, promise);
        return promise;
    }
}
const fileRetriever: FileRetriever = new FileRetriever();
export default fileRetriever;