import { CircularProgress, LinearProgress } from '@mui/material';
import { CToast, CToastBody, CToastClose } from '@coreui/react-pro';
import { LoaderType } from '../../services/redux/globalStateManager';

interface LoaderProps {
    type: LoaderType;
    status?: 200 | 400 | 401 | 403 | 404 | 500 | 503;
    description?: string;
}

const Loader = (props: LoaderProps): JSX.Element => {
    const { type, description } = props;

    const styles = {
        zIndex: 9999,
        position: 'fixed',
        background: 'transparent',
    };

    if (!type) return <></>;

    if (type === 'circular') {
        return <CircularProgress sx={{ ...styles }} />;
    }

    if (type === 'linear') {
        return <LinearProgress sx={{ ...styles, top: 0, left: 0, width: '100%' }} />;
    }

    if (type === 'toast-err') {
        return (
            <CToast
                style={{
                    zIndex: 999999,
                    position: 'fixed',
                    top: '1rem',
                    right: '1rem',
                }}
                animation={true}
                visible={true}
                color="danger"
                className="text-white align-items-center"
            >
                <div className="d-flex align-items-center">
                    <svg
                        style={{ marginLeft: '1rem' }}
                        className="flex-shrink-0 me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 512 512"
                    >
                        <rect
                            width="32"
                            height="176"
                            x="240"
                            y="176"
                            fill="var(--ci-primary-color, currentColor)"
                            className="ci-primary"
                        ></rect>
                        <rect
                            width="32"
                            height="32"
                            x="240"
                            y="384"
                            fill="var(--ci-primary-color, currentColor)"
                            className="ci-primary"
                        ></rect>
                        <path
                            fill="var(--ci-primary-color, currentColor)"
                            d="M274.014,16H237.986L16,445.174V496H496V445.174ZM464,464H48V452.959L256,50.826,464,452.959Z"
                            className="ci-primary"
                        ></path>
                    </svg>
                    <CToastBody>{description}</CToastBody>
                    <CToastClose className="me-2 m-auto" />
                </div>
            </CToast>
        );
    }

    return <></>;
};

export default Loader;

