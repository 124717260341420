import { Source } from '../../types';

/**
 * This array enables us to iterate over all the field types, while still having type safety,
 * while still making sure type FieldType is updated automatically with every new array entry.
 */
export const fieldType = [
  'autocomplete',
  'text',
  'letters',
  'textarea',
  'regex',
  'selection',
  'date',
  'date-range',
  'time',
  'number',
  'email',
  'password',
  'location',
  'link',
  'file',
  'radio',
  'signature',
  'map',
  'selectable-list',
  'button',
  'repairCostField',
  'expandable-items',
] as const;

export const defaultInputTypes = [
  'button',
  'checkbox',
  'color',
  'date',
  'datetime-local',
  'email',
  'file',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'radio',
  'range',
  'reset',
  'search',
  'submit',
  'tel',
  'text',
  'time',
  'url',
  'week',
  'expandable-items',
] as const;

export type FieldType = (typeof fieldType)[number];

export enum Permission {
  NONE = 0o000,
  CREATE = 1000,
  READ = 0o100,
  WRITE = 0o010,
  DELETE = 0o001,
  CR = 1100,
  CRW = 1110,
  RW = 0o110,
  RWD = 0o111,
  CRWD = 1111
}
export const deletePermissionIndex = 3;
export const writePermissionIndex = 2;
export const readPermissionIndex = 1;
export const createPermissionIndex = 0;
export type FieldSize = `h${number}` | `l${number}` | `b${number},${number}`;

// Represents a key in memory's global storage. for example: 'car-ocr-form.barcode-img.base64'
export type fieldKey = string;

export interface FieldInterface {
  type: FieldType;
  subtype?: 'single' | 'multiple' | 'image';
  value?: string; // Represents a field's default value
  _key: string;
  label?: string;
  required?: boolean;
  permission?: Permission;
  amount?: number;
  size?: FieldSize;
  icon?: Source;
  source?: Source;
  _prerequisite?: Source;
}
