import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router-dom';
import { _capitalize } from '../utils';

function generateBreadcrumbs(location: Location) {
    // Split the path to get individual segments of the URL
    const pathnames = location.pathname.split('/').filter((x) => x);

    return pathnames.map((value, index) => {
        // Construct the pathname up to the current segment
        const pathnameToCurrentSegment = `/${pathnames.slice(0, index + 1).join('/')}`;

        return {
            name: _capitalize(value), // Assumes that the segment can be directly used as a name after capitalizing
            pathname: pathnameToCurrentSegment,
            active: index === pathnames.length - 1, // The last segment is the active one
        };
    });
}

const AppBreadcrumb = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const breadcrumbs = generateBreadcrumbs(location);

    return (
      <CBreadcrumb className="m-2 mx-0 px-[8rem]">
            <CBreadcrumbItem color="indigo" href="/">
                {t('Home')}
            </CBreadcrumbItem>
            {breadcrumbs.map((breadcrumb) => (
                <CBreadcrumbItem {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })} key={breadcrumb.name}>
                    {t(breadcrumb.name?.toLowerCase() as string)}
                </CBreadcrumbItem>
            ))}
        </CBreadcrumb>
    );
};

export default React.memo(AppBreadcrumb);
