import { CFormLabel } from '@coreui/react-pro';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionOption } from '../../../types';
import StyleWrapper from '../../style-wrapper';
import Label from '../Label';
import { Box } from '@mui/material';
import { updateRuntimeData } from '../../../services/redux/globalStateManager';
import useAppDispatch from '../../../services/redux/useAppDispatch';
import { FieldInterface } from '../../input/types';

const Selection = (props: {field: FieldInterface}) => {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState<SelectionOption[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const _options = props.options ?? [];
    const dispatch = useAppDispatch();
    const { field } = props;

    const autocompleteProps = {
        sx: { width: '100%', flex: 1 },
        className: 'dark-theme',
        disablePortal: true,
        id: 'combo-box-demo',
        options: Array.isArray(field.value) ? field.value : [],
    };
    useEffect(() => {
        const initialValue = field.value ?? field.source?.defaultValue;
        const initialOption = _options?.find(option => option._id === initialValue);
        if (initialOption) {
            setSelectedOptions([initialOption]);
            setSearchInput(initialOption.label);
        }
        if (initialValue) {
          dispatch(updateRuntimeData(`${props.id}.value`, initialValue, 'merge'));
          dispatch(updateRuntimeData(`${props.id}.validated`, 'true', 'merge'));
        }
    }, [_options]);

    return (
        <StyleWrapper display="flex" alignItems="center" paddingLeft="0px" paddingRight="0px">
            {field.label && (
                <CFormLabel>
                    <Label required={field.required}>{t(field.label)}</Label>
                </CFormLabel>
            )}

            <Box
                sx={{
                    width: 300,
                    '& *': { color: 'var(--bt-test-primary-100) !important' },
                    '& div[role="presentation"] *': { backgroundColor: 'var(--bt-bg-10)' },
                }}
            >
                {field.subtype === 'multiple' ? (
                    <Autocomplete
                        {...autocompleteProps}
              disabled
                        options={_options ?? []}
                        multiple={true}
                        loadingText={t('Loading...')}
                        disablePortal
                        getOptionLabel={option => t(option.label)}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderInput={params => <TextField {...params} placeholder={t(field._placeholder ?? 'Please select...')} />}
                        onChange={(_, newValue: readonly SelectionOption[], reason: AutocompleteChangeReason) => {
                            // Handle the clear action separately
                            if (reason === 'clear') {
                                setSelectedOptions([]);
                                props.onChange && props.onChange(undefined);
                                return;
                            }

                            let updatedOptions: SelectionOption[] = [];

                            // newValue contains all currently selected options for 'select-option' and 'remove-option' reasons
                            if (reason === 'selectOption' || reason === 'removeOption') {
                                updatedOptions = newValue.map(option => option._id);
                            } else {
                                // For other reasons that might affect selection, adjust logic as needed
                            }

                            // Update the selectedOptions state.
                            setSelectedOptions(updatedOptions);

                            // Notify the parent component with the updated values.
                            const _stringRepresentation = updatedOptions.join(',');
                            props.onChange && props.onChange(_stringRepresentation);
                        }}
                    />
                ) : (
                    <Autocomplete
                        {...autocompleteProps}

                        options={_options ?? []}
                        defaultValue={_options?.find(option => option._id === field.value)}
                        defaultChecked={_options?.find(option => option._id === field.value)}
                        value={selectedOptions[0] || null}
                        noOptionsText={t('No options')}
                        getOptionLabel={option => t(option.label)}
                        inputValue={searchInput ?? selectedOptions[0]?.label ?? ''}
                        disablePortal
                        renderInput={params => (
                            <TextField
                                {...params}
                                color="success"
                                style={{ color: 'white' }}
                                sx={{ color: 'white' }}
                                onClick={() => {
                                    setSearchInput('');
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    onMouseDown: event => event.stopPropagation(),
                                    onClick: event => event.stopPropagation(),
                                }}
                                onChange={e => setSearchInput(e.target.value)}
                                placeholder={`${t(selectedOptions[0]?.label?.toLowerCase() ?? t('Choose') + '...')}`}
                            />
                        )}
                        multiple={false}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        onChange={(_, newValue: SelectionOption | null) => {
                            if (props.onChange) {
                                props.onChange(newValue?._id);
                                setSearchInput(t(newValue?.label?.toLowerCase() ?? ''));
                                setSelectedOptions([newValue]);
                            }
                        }}
                    />
                )}
            </Box>
        </StyleWrapper>
    );
};

export default Selection;
