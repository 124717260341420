import {
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from '@coreui/react-pro';
import { PropsWithChildren, useState, useMemo } from 'react';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { Mode } from '../../types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { TABVIEW_MIN_FIELDS } from '../../configuration/constants';

interface ExpandedRowModal {
  id: string;
  maxItemsPerTab?: number;
}

const ExpandedRowModal = (props: PropsWithChildren<ExpandedRowModal>) => {
  const { id, maxItemsPerTab = TABVIEW_MIN_FIELDS } = props;
  const runtime = useAppSelect('runtime');
  const dispatch = useAppDispatch();
  const { mode, fields, modal, selectedRow } = runtime[id] ?? {};
  const title = runtime[id]?.modal?.title ?? '';
  const { t } = useTranslation();

  // Organize fields by their tab titles
  const organizedTabs = useMemo(() => {
    if (!fields) return new Map();

    const tabGroups = new Map();

    // First, separate fields into those with and without tabIndex
    const fieldsWithTab = [];
    const fieldsWithoutTab = [];

    Object.entries(fields).forEach(([key, field]) => {
      if (field.tabTitle !== undefined) {
        fieldsWithTab.push([key, field]);
      } else {
        fieldsWithoutTab.push([key, field]);
      }
    });

    // Process fields with explicit tab titles
    fieldsWithTab.forEach(([key, field]) => {
      const { tabTitle } = field;
      if (!tabGroups.has(tabTitle)) {
        tabGroups.set(tabTitle, []);
      }
      tabGroups.get(tabTitle).push([key, field]);
    });

    // Handle fields without tab assignment
    if (fieldsWithoutTab.length > 0) {
      let currentChunk = [];
      let chunkIndex = 1;

      fieldsWithoutTab.forEach(([key, field], index) => {
        currentChunk.push([key, field]);

        // When chunk is full or it's the last item
        if (currentChunk.length === maxItemsPerTab ||
          index === fieldsWithoutTab.length - 1) {
          // Create a generic tab title for ungrouped fields
          const autoTabTitle = chunkIndex === 1 ? t('Main') : t('Details');

          if (!tabGroups.has(autoTabTitle)) {
            tabGroups.set(autoTabTitle, []);
          }
          tabGroups.get(autoTabTitle).push(...currentChunk);

          currentChunk = [];
          chunkIndex++;
        }
      });
    }

    return tabGroups;
  }, [fields, maxItemsPerTab]);

  // Get the first tab title for initial active tab
  const initialTabTitle =
    organizedTabs.size > 0 ? Array.from(organizedTabs.keys())[0] : '';

  // State to manage active tab
  const [activeTab, setActiveTab] = useState(initialTabTitle);

  const handleClose = () => {
    dispatch(updateRuntimeData(`${id}.mode`, Mode.VIEW));
  };

  return (
    <CModal size="xl" onClose={handleClose} visible={mode === Mode.EXPAND_ROW}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>

      <CModalBody style={{ minHeight: '30vh' }}>
        {/* Navigation tabs - now showing meaningful titles */}
        <CNav variant="tabs" style={{ gap: 5 }}>
          {Array.from(organizedTabs.keys()).map((tabTitle) => (
            <CNavItem style={{ borderRadius: '8px' }} key={`tab-${tabTitle}`}>
              <CNavLink
                style={{ borderRadius: '8px' }}
                active={activeTab === tabTitle}
                onClick={() => setActiveTab(tabTitle)}
              >
                {t(tabTitle)} {/* Using translation for tab titles */}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>

        {/* Tab content */}
        <CTabContent>
          {Array.from(organizedTabs.entries()).map(([tabTitle, tabFields]) => (
            <CTabPane
              key={`content-${tabTitle}`}
              visible={activeTab === tabTitle}
              className="p-3"
            >
              <div className="row g-3"  >
                {tabFields.map(([key, field]) => (
                  <div style={{ paddingBottom: '1rem' }} key={key} className="col-md-4">
                    <div style={{ minHeight: '6rem', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '8px', padding: '1rem' }} className="form-group">
                      <CFormLabel className="fw-bold">
                        {t(field._key)}
                      </CFormLabel>
                      <div className="form-control-plaintext">
                        {selectedRow && selectedRow[field._key]}
                      </div>

                    </div>
                  </div>
                ))}
              </div>
            </CTabPane>
          ))}
        </CTabContent>
      </CModalBody>
    </CModal >
  );
};

export default ExpandedRowModal;
